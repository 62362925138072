exports.objectToQueryParams = (obj) => {
    const encodedParams = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    const encodedValue = encodeURIComponent(value[i]);
                    encodedParams.push(key + '[]=' + encodedValue);
                }
            } else {
                const encodedValue = encodeURIComponent(value);
                encodedParams.push(key + '=' + encodedValue);
            }
        }
    }

    return encodedParams.join('&');
}